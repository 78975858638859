import(/* webpackMode: "eager" */ "/root/app/production/thehawk-frontend/node_modules/.pnpm/@next+third-parties@14.2.12_next@14.2.13_@babel+core@7.25.2_react-dom@18.2.0_react@18.2.0__re_br2rsw7ru23u6kjspizqjy6v6y/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/root/app/production/thehawk-frontend/node_modules/.pnpm/@next+third-parties@14.2.12_next@14.2.13_@babel+core@7.25.2_react-dom@18.2.0_react@18.2.0__re_br2rsw7ru23u6kjspizqjy6v6y/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/root/app/production/thehawk-frontend/node_modules/.pnpm/@next+third-parties@14.2.12_next@14.2.13_@babel+core@7.25.2_react-dom@18.2.0_react@18.2.0__re_br2rsw7ru23u6kjspizqjy6v6y/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/root/app/production/thehawk-frontend/node_modules/.pnpm/next@14.2.13_@babel+core@7.25.2_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.79.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/root/app/production/thehawk-frontend/node_modules/.pnpm/next@14.2.13_@babel+core@7.25.2_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.79.1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/root/app/production/thehawk-frontend/node_modules/.pnpm/next@14.2.13_@babel+core@7.25.2_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.79.1/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/app/production/thehawk-frontend/src/providers/OneSignalProvider.tsx");
