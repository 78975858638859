'use client'
import  {  useEffect } from 'react'
export default function OneSignalProvider( ) {
 const importAndInitOneSignal = async () => {
    const OneSignal  = await import("react-onesignal").then(mod=> mod.default);
    
    OneSignal.init({
        appId: "b1b0ebf9-8eee-4229-b20d-56969773a49d",

    });
 }
    useEffect(() => {
        importAndInitOneSignal()

    }, []);



    return null
}
